/* css debug mode */
/* * {
  background: rgba(0, 110, 0, 0.05);
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

html,
body,
#app {
  width: 100%;
  min-height: 100vh;
}

html,
body,
#app {
  /* This should always be active but it conflicts with the BasePageLayout, so the WholePageLayout injects it through Global CSS */
  /* height: 100%; */
}

a {
  text-decoration: none;
  color: inherit;
}

/* remove cell outline highlight on click/focus */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.ql-editor {
  min-height: 80px;
}
